<template>
  <div :class="[...(loadingList ? ['mb-4 pb-4'] : [])]">
    <div class="before-workflow-list">
      <div style="width:480px">
        <v-tabs fixed-tabs class="before-workflow-list-tab mt-4"
          v-if="this.type === 'approval'"
          :slider-color="color"
          @change="changeTab"
        >
          <v-tab
            v-for="(tab, ti) in tabList"
            active-class="approval--text"
            :key="`workflow-tab-${ti}`"
          >
            {{ tab }}
          </v-tab>
        </v-tabs>
      </div>
      <v-text-field
        v-model="searchKeyword"
        label="Search"
        @focus="focusSearch"
        @blur="blurSearch"
        @change="changeSearch"
        color="font-color-default"
        class="before-workflow-list-search">
        <template v-slot:append>
          <v-icon
            dark
            :color="canSearch ? 'primary' : 'grey'"
            @click="changeSearch"
            >
            mdi-magnify
            </v-icon>
        </template>
      </v-text-field>
    </div>
    <flexible-list
      class="workflow-list"
      :headers="workflowFields"
      :items="workflows"

      :loading="loadingList"
      :mini-size="722"
      :mini-columns="3"

      :title-style="{
        'background-color': 'white',
        color: 'rgba(0, 0, 0, .68)',
        'font-weight': 400
      }"
      :mini-columns-style="miniColumnsStyle"
      :item-hover-style="itemHoverStyle"
      :mini-item-hover-style="miniItemHoverStyle"

      @click-item="clickWorkflowItem"
    >
      <template v-slot:slot-icon>
        <v-icon color="grey">mdi-file-document-edit-outline</v-icon>
      </template>
      <template v-slot:slot-request-id="props">
        <div style="font-weight:500;">
          {{ props.item.requestId }}
        </div>
        <div style="font-size:13px;">
          {{ props.item.requestType }}
        </div>
        <div class="grey--text text-keep-1-row" style="font-size:12px;">
          {{ props.item.requestSubType || '-' }}
        </div>
      </template>
      <template v-slot:slot-opp-id="props">
        <div style="text-align: center;">
          <div
            v-for="(opp, oi) in props.item.opportunityIds"
            :key="'item_' + props.row + '_opp_id_' + oi"
          >
            <div 
              v-if="oi < 2 || (oi == 2 && props.item.opportunityIds.length == 3) || props.item.viewAllOpp"
              :class="['opp-chip', ...(oi > 0 ? ['mt-1'] : [])]"
            >
              {{ opp }}
            </div>
          </div>
          <div style="font-size:12px;">{{ props.item.projectId }}</div>
          <div v-if="props.item.opportunityIds.length === 0 && !props.item.projectId">-</div>
          <v-icon 
            v-if="props.item.cntOpp > 1 || props.item.cntProj > 1" 
            size="18"
            class="view-more-opp-btn-text btn-flat-color-default"
            style="line-height:9px;vertical-align:middle;">
            mdi-dots-horizontal
          </v-icon>
        </div>
      </template>
      <template v-slot:slot-sap="props">
        <div>{{ props.item.accountName }}</div>
        <div style="font-size:12px">{{ props.item.soldToParty }}</div>
      </template>
      <template v-slot:slot-approver="props">
        <div v-if="['', '-'].indexOf(props.item.approverEmail) > -1">-</div>
        <account-profile
          v-else
          :email="props.item.approverEmail"
          :icon="props.item.approverIcon"
          :name="props.item.approverName"
          :tag-color="themeColor.default"
          style="width: 140px"
        >
        </account-profile>
      </template>
      <template v-slot:slot-last-updated-by="props">
        <div v-if="['', '-'].indexOf(props.item.lastUpdatedByEmail) > -1">System</div>
        <account-profile
          v-else
          :hide-icon="true"
          :hide-tooltip="true"
          tag-overflow="wrap"
          :email="props.item.lastUpdatedByEmail"
          :icon="props.item.lastUpdatedByIcon"
          :name="props.item.lastUpdatedByName"
          :tag-color="themeColor.default"
          style="width: 140px"
        >
        </account-profile>
        <div>{{ props.item.lastUpdatedTime.substring(0, 10) }}</div>
      </template>
    </flexible-list>
    <template v-if="workflows.length > 0 && !loadingList">
      <template v-if="!loadingMore">
        <div class="view-more-workflow mt-4">
          <v-tooltip nudge-right="12" left :disabled="isMobile">
            <template v-slot:activator="{ on }">
              <v-btn small text :color="color" class="view-more-workflow-btn"
                v-on="on"
                @click="viewMoreData()"
              >
                <div class="view-more-workflow-btn-text">
                  <v-icon class="view-more-workflow-btn-icon mt-n3" size="24">mdi-chevron-double-down</v-icon>
                </div>
                <!-- <div>View More</div> -->
              </v-btn>
            </template>
            <span>View More</span>
          </v-tooltip>
        </div>
      </template>
      <div
        v-else
        style="width:88px;height:44px;margin:0 auto;padding:18px 4px 0 0;">
        <v-progress-circular
          :width="3"
          color="grey"
          indeterminate
          :size="24"
        ></v-progress-circular>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { xPost } from '@/functions/http/axios'
import flexibleList from '@/components/vuetify-extension/flexible-list'
import AccountProfile from '@/components/vuetify-extension/account-profile'

export default {
  name: 'WorkflowList',
  components: {
    AccountProfile,
    flexibleList,
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    type: {
      type: String,
      default: 'approval',
      validator (value) {
        return ['approval', 'contract', 'wbs'].indexOf(value) > -1
      }
    },
    dataPerBatch: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      dataBatches: 1,
      loadingList: false,
      loadingMore: false,
      loadTime: null,
      searchKeyword: '',
      searchFocused: false,
      lastSearchKeyword: '',
      tabSelected: (this.type === 'approval' ? 'Waiting' : 'All'),
      tabList: [
        'Waiting',
        'Finished',
        'All'
      ],
      workflows: [
        // {
        //   requestId: '',
        //   token: '',
        //   opportunityIds: [],
        //   cntOpp: 0,  // 真实 opportunity 数，一开始只读一条
        //   cntProj: 0,  // 真实 project 数，一开始只读一条
        //   requestType: '',
        //   contractType: '', // if is client project contract
        //   soldToParty: '',
        //   status: '',
        //   approverEmail: '',
        //   approverIcon: '',
        //   approverName: '',
        //   lastUpdatedTime: '',
        //   lastHistoryId: '',
        //   viewAllOpp: false
        // }
      ],

      sortTitle: "name",
      sortOrder: "asc",

      itemHoverStyle: {
        'background-color': 'rgba(150, 150, 150, .1)',
        cursor: 'pointer'
      },
      miniItemHoverStyle: {
        cursor: 'pointer'
      },
      miniColumnsStyle: [
        {
          'width': '48px',
          'max-width': '48px',
          'text-align': 'center',
          'justify-content': 'center'
        },
        {
          'min-width': '180px'
        },
        {
          position: 'relative',
          'width': '180px',
          'vertical-align': 'top'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', {
      themeColor: state => state.color
    }),
    ...mapGetters('app', [
      'isMobile'
    ]),
    ...mapGetters('user', [
      'loginStatus'
    ]),
    workflowFields () {
      return [
        // {
        //   titleStyle: { padding: 0 },
        //   valueStyle: { padding: 0 }
        // },
        {
          slot: 'slot-icon',
          miniColumn: 1,
          titleStyle: {
            'width': '48px',
            'min-width': '48px',
            'max-width': '48px'
          },
          valueStyle: {
            'width': '48px',
            'min-width': '48px',
            'max-width': '48px',
            'text-align': 'center'
          }
        },
        {
          title: 'Request ID',
          slot: 'slot-request-id',
          miniColumn: 2,
          titleStyle: {
            'width': '120px',
            'min-width': '120px'
          },
          valueStyle: {
            'width': '120px',
            'min-width': '120px',
            'max-width': '180px'
          }
        },
        {
          title: 'Opportunity ID/ Project ID',
          slot: 'slot-opp-id',
          titleStyle: {
            'text-align': 'center',
            'width': '160px',
            'min-width': '160px'
          }
        },
        {
          title: 'Account Name/ Sold To Party',
          slot: 'slot-sap',
          titleStyle: {
            'width': '270px'
          },
          miniColumn: 2,
          miniValueStyle: {
            'font-size': '14px'
          }
        },
        {
          title: 'Status',
          value: 'status',
          miniColumn: 3,
          titleStyle: {
            'width': '200px'
          },
          miniItemStyle: {
            'font-size': '14px',
            'position': 'relative'
          },
          miniValueStyle: {
            'position': 'absolute',
            'top': '0',
            'right': '0',
            'text-align': 'right',
            'color': 'grey'
          }
        },
        {
          title: 'Approver',
          slot: 'slot-approver',
          titleStyle: {
            'min-width': '170px',
            'width': '170px'
          },
          valueStyle: {
            'width': '170px',
            'min-width': '170px',
            'max-width': '170px'
          }
        },
        // {
        //   title: 'Last Updated By',
        //   value: 'lastUpdatedTime',
        //   hideWidth: 939,
        //   titleStyle: {
        //     'text-align': 'right',
        //     'width': '160px'
        //   },
        //   valueStyle: {
        //     'text-align': 'right'
        //   }
        // },
        {
          title: 'Last Updated By',
          slot: 'slot-last-updated-by',
          hideWidth: 939,
          titleStyle: {
            'width': '140px',
            'max-width': '140px',
            'min-width': '140px'
          },
          valueStyle: {
            'width': '140px',
            'max-width': '140px',
            'min-width': '140px'
          }
        }
      ]
    },
    
    canSearch () {
      return (this.searchKeyword && (this.searchKeyword != this.lastSearchKeyword))
    }
  },
  methods: {
    clickWorkflowItem (item) {
      this.$emit('click-item', item)
    },
    viewMoreOpp (index) {
      setTimeout(() => {
        this.workflows[index].viewAllOpp = true
      }, 100);
    },

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.sortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    postParams () {
      let request = (() => {
        switch (this.type) {
          case 'contract':
            return 'Contract'
            break;
          
          case 'wbs':
            return 'WBS'
            break;
        
          default:
            return 'All'
            break;
        }
      })()
      
      let status = (() => {
        if (['contract', 'wbs'].indexOf(this.type) > -1) {
          return 'Requestor'
        } else if (this.tabSelected == 'All') {
          return 'Approval'
        } else {
          return this.tabSelected
        }
      })()

      return {
        request,
        status
      }
    },

    /**
     * get data
     */
    async requestWorkflowList (reload) {
      if (this.loadingList && !reload) return
      this.loadingList = reload
      if (reload) { this.workflows.splice(0, this.workflows.length) }
      let loadTime = new Date()
      this.loadTime = loadTime
      try {
        let counts = {}
        if (!reload && this.workflows.length) {
          this.workflows.forEach(el => {
            if (!!el.requestType) {
              if (!!counts[el.requestType]) {
                counts[el.requestType]++
              } else {
                counts[el.requestType] = 1
              }
            }
          });
        }
        const res = await xPost(
          'workflow_list',
          'getwflist3d2',
          {
            loginStatus: this.loginStatus,
            keyword: this.searchKeyword,
            counts,
            step: 10,
            ...this.postParams()
          }
        )
        if (this.loadTime == loadTime) {
          if (res.data.status === 200 && res.data.data) {
            let data = res.data.data
              .filter(el => {
                return !this.workflows.find(el2 => {
                  return el.RequestId == el2.requestId
                })
              })
              .sort((a, b) => {
                let awo = a.WaitingOrder || null
                let bwo = b.WaitingOrder || null
                awo = parseInt(awo) || null
                bwo = parseInt(bwo) || null

                let atime = a.LastUpdatedTime || null
                let btime = b.LastUpdatedTime || null

                if (this.tabSelected == 'Waiting') {
                  if (awo > bwo) return 1
                  if (awo < bwo) return -1
                }

                if (this.tabSelected == 'Waiting') {
                  if (atime > btime) return 1
                  return -1
                } else {
                  if (atime < btime) return 1
                  return -1
                }
              })
              .slice(0, 10)
              .map(el => ({
                requestId: el.RequestId || '',
                token: el.Token || '',
                opportunityIds: !!el.OpportunityId ? [el.OpportunityId] : [],
                projectId: el.ProjectId || '',
                cntOpp: parseInt(el.CountOpportunity || '') || 0,
                cntProj: parseInt(el.CountProject || '') || 0,
                requestType: el.RequestType || '',
                requestSubType: el.RequestSubType || '',
                accountName: el.AccountName || '-',
                soldToParty: el.SoldToParty || '-',
                status: el.Status || '',
                approverEmail: el.ApproverEmail || '',
                approverIcon: el.ApproverIcon || '',
                approverName: el.ApproverName || '',
                lastUpdatedByEmail: el.LastUpdatedEmail || '',
                lastUpdatedByIcon: el.LastUpdatedIcon || '',
                lastUpdatedByName: el.LastUpdatedName || '',
                lastUpdatedTime: el.LastUpdatedTime || '',
                hid_contract: parseInt(el.hid_contract || '') || 0,
                hid_wbs: parseInt(el.hid_wbs || '') || 0,
                viewAllOpp: false
              }))
              
            if (reload) {
              this.workflows = data
            } else {
              this.workflows = [...this.workflows, ...data]
            }
            this.lastSearchKeyword = this.searchKeyword
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                // this.$router.push('/login')
                this.$emit('auth-fail')
                throw 'Authorize Failed'
              }
            } catch (e) {}
            throw {
              response: {
                status: res.data.status
              }
            }
          }
          setTimeout(() => {
            this.loadingList = false
          }, 500);
        }
      } catch (err) {
        console.log('Error [load data - client project contract]')
        console.log(err)
      }
    },

    focusSearch () {
      this.searchFocused = true
    },
    blurSearch () {
      this.searchFocused = false
    },
    changeSearch () {
      if (this.searchFocused) {
        this.searchData()
      }
    },
    searchData () {
      if (this.searchKeyword == this.lastSearchKeyword) {return}
      this.requestWorkflowList(true)
    },

    async viewMoreData () {
      setTimeout(() => {
        this.loadingMore = true        
      }, 100);
      const oldCount = this.workflows.length
      await this.requestWorkflowList(false)
      const newCount = this.workflows.length
      if (newCount !== oldCount) {
        this.dataBatches++
      } else {
        this.$eventBus.$emit('snackbar', { text: 'No more case', type: 'info', time: '1.75' })
      }
      setTimeout(() => {
        this.loadingMore = false    
      }, 100);
    },
    changeTab (index) {
      this.tabSelected = this.tabList[index]
      this.requestWorkflowList(true)
    }
  },
  created () {
    this.requestWorkflowList(true)
  }
}
</script>

<style lang="sass">
.text-keep-1-row
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis


.before-workflow-list
  // display: flex
  // flex-wrap: wrap
  // align-items: center

  &-tab
    margin-bottom: 19px
    border-bottom: 1px solid lightgrey
    float: left
  

  &-btn-new
    margin-left: 6px
  

  &-search
    margin-left: 30px
    min-width: 220px
    max-width: 220px
    padding-right: 6px
    float: right
  


.workflow-list
  border-bottom: none

  .opp-chip
    padding: 4px
    border-radius: 4px
    color: black
    background-color: #e3e7f1
    text-align: center
  


.workflow-list-action-box
  position: absolute
  top: 0
  left: 0
  right: 0
  z-index: 2
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12)

  .workflow-list-action
    display: flex
    padding: 3px 0 0 0
    min-height: 48px

    .workflow-list-action-add
      padding-left: 6px
    

    .workflow-list-action-filter
      padding-left: 3px !important
      padding-right: 3px !important
      width: 75px

      button
        padding-left: 5px
        padding-right: 0
        min-width: 0
      
    
  


.workflow-list-btn
  margin-top: 2px !important
  margin-bottom: 2px !important

  &:hover:before
    background: none !important
  

  &:active
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !important
  

  &-more
    cursor: pointer
    // text-decoration: underline
  


.view-more-opp
  &-btn
    height: 100%
    align-items: flex-start
    padding-top: 6px!important
    padding-bottom: 6px!important
    margin: 0 !important
    border-radius: 0 !important

    &-text
      font-weight: 300
    

    &-icon
      margin-right: 4px
    
  


.view-more-workflow
  width: 100%
  text-align: center

  &-btn
    height: 100%
    align-items: flex-start
    margin: 0 !important
    border-radius: 0 !important

    &-text
      margin: 12px auto
      font-weight: 300
    

    &-icon
      margin-right: 4px
    
  

</style>
